import create from 'zustand';
import Hours from '../api/Hours';
import { Hour } from '../models/Hour';

interface IHours {
    hours: Hour[],
    findedHour: Hour | null,
    listHours: (dateFrom: string, dateTo: string, username?: string, description?: string) => void,
    createHour: (description: string, hours: number, created: string, username: string) => void,
    findHour: (id: string) => void,
    updateHour: (id: string, description: string, hours: number, created: string) => void,
    deleteHour: (id: string) => void
}

export const useHoursStore = create<IHours>((set, get) => ({
    hours: [],
    findedHour: null,
    listHours: async (dateFrom: string, dateTo: string, username?: string, description?: string) => {
        const newHours = await Hours.ListHours(dateFrom, dateTo, username, description) as Hour[];
        set({ hours: newHours });
    },
    createHour: async (description: string, hours: number, created: string, username: string) => {
        await Hours.CreateHour(description, hours, created, username);
    },
    findHour: async (id: string) => {
        const hour = await Hours.FindHour(id) as Hour;
        set({ findedHour: hour });
    },
    updateHour: async (id: string, description: string, hours: number, created: string) => {
        await Hours.UpdateHour(id, description, hours, created);
    },
    deleteHour: async (id: string) => {
        await Hours.DeleteHour(id);
    }
}));