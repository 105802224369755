import { useTranslation } from "react-i18next";
import CheckboxLabel from "../UI/molecules/CheckboxLabel";
import Auth from '../../api/Auth';
import { useForm } from "react-hook-form"
import FieldValidator from "../UI/molecules/FieldValidator";
import { useRef, useState } from "react";
import Logo from "../UI/atoms/Logo";
import { AlertMessage, Button, IAlertMessage, TypeAlertMessage, LinkAction, GoogleButton, PageLoader } from "@sixma/web-ui";
import { useAuth } from "../../domains/auth/providers/Auth";


function Login() {
    const { register, handleSubmit,
        formState: { errors }, } = useForm()

    const [validating, setValidating] = useState(false);
    const [providerValidating, setProviderValidating] = useState(false);

    const alertRef = useRef<IAlertMessage>();

    const { isValidatingAuth, login } = useAuth();



    const onSubmit = async (data: any) => {
        try {
            setValidating(true);
            await login(data.email, data.password, false)
        } catch (error: any) {
            console.log(error)
            if (error.toString().indexOf("UserNotFoundException") !== -1)
                alertRef.current?.show(t("Login.userNotFoundError"), TypeAlertMessage.error);
            else if (error.toString().indexOf("NotAuthorizedException") !== -1)
                alertRef.current?.show(t("Login.passwordOrEmailError"), TypeAlertMessage.error);
            else
                alertRef.current?.show(t("Login.otherError"), TypeAlertMessage.error);

        } finally {
            setValidating(false);
        }
    }

    const goToGoogle = () => {
        setProviderValidating(true);
        Auth.SignWithRedirect();
        setTimeout(() => setProviderValidating(false), 3000);
    };

    const { t } = useTranslation();
    return (
        <>
            {((isValidatingAuth || providerValidating) && <PageLoader classNameSpin="text-sixma-2"> {t("General.loadingText")}</PageLoader>)}

            <AlertMessage ref={alertRef}></AlertMessage>

            <div className="flex min-h-screen justify-center ">
                <div className="flex   flex-col sm:mt-0   sm:flex-col justify-center px-4 w-full bg-gray-100  sm:px-6 lg:flex-none lg:px-20 xl:px-24">
                    <div className="mx-auto w-full max-w-sm lg:w-96">
                        <div>
                            <Logo className="m-auto h-28" isVertical={true} />
                            <h2 className="mt-3 text-2xl font-bold leading-9 tracking-tight text-gray-900 text-center ">
                                {t("Login.title")}
                            </h2>

                        </div>

                        <div className="mt-2  bg-white px-8 py-6 shadow rounded-md">
                            <div>
                                <form onSubmit={handleSubmit(onSubmit)} className=" ">

                                    <div className="mt-0">
                                        <FieldValidator
                                            errors={errors} labelText={t("Login.emailNameField")} name="email" register={register} type="email" validationSchema={{
                                                required: t("Login.emailErrorRequired")
                                            }} placeholder={t("Login.emailPlaceholder")} />
                                    </div>

                                    <div className="mt-3">
                                        <FieldValidator
                                            errors={errors} labelText={t("Login.passwordNameField")} name="password" register={register} type="password" validationSchema={{
                                                required: t("Login.passwordErrorRequired")
                                            }} />
                                    </div>
                                    <div className="flex items-center justify-center mt-2">
                                        {/*         <div className="flex items-center">
                                            <CheckboxLabel id="remember-me" name="remember-me" text={t("Login.rememberNameField")} />
                                        </div>
 */}
                                        <div className="text-sm leading-1">
                                            <LinkAction to="/account/recover-password" className="text-sixma-1 hover:text-sixma-1-500">{t("Login.forgotPassword")}</LinkAction>
                                        </div>
                                    </div>

                                    <div className="mt-6">
                                        <Button
                                            type="submit"
                                            className="w-full px-3 py-2.5 justify-center items-center bg-sixma-2 disabled:bg-sixma-2/60 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none text-white disabled:text-white text-sm"
                                            isEnabled={!validating}
                                            isLoading={validating}>
                                            {t("Login.signInButton")}
                                        </Button>
                                    </div>

                                   
                                </form>
                            </div>

                            <div className="mt-6">
                                <div className="relative">
                                    <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                        <div className="w-full border-t border-gray-200" />
                                    </div>
                                    <div className="relative flex justify-center text-sm font-medium leading-6">
                                        <span className="bg-white px-6 text-gray-900">{t("Login.orContinueWith")}</span>
                                    </div>
                                </div>

                                <div className="mt-3 grid grid-cols-1 gap-4">

                                    <GoogleButton click={() => {
                                        goToGoogle()
                                    }} className="w-full"></GoogleButton>
                                </div>
                            </div>
                        </div>
                        <p className="mt-6 text-sm leading-6 text-gray-500 text-center">
                                        {t("Login.isNotRegister")}{' '}
                                        <LinkAction to="/account/register" className="text-sixma-1 hover:text-sixma-1-500">{t("Login.registerHere")}</LinkAction>
                                    </p>
                    </div>
                </div>
         {/*        <div className="relative hidden w-0 flex-1 lg:block">
                    <img
                        className="absolute inset-0 h-full w-full object-cover"
                        src="https://images.unsplash.com/photo-1496917756835-20cb06e75b4e?ixlib=rb-1.2.1&ixid=eyJhcHBfaWQiOjEyMDd9&auto=format&fit=crop&w=1908&q=80"
                        alt=""
                    />
                </div> */}
            </div></>
    );
}

export default Login;