import create from 'zustand';

interface ISidebar {
    open: boolean,
    setOpen: (newOpen: boolean) => void
}

export const useSidebarStore = create<ISidebar>((set, get) => ({
    open: false,
    setOpen: (newOpen: boolean) => {
        set({ open: newOpen })
    },
}));