import * as React from 'react';
import Auth from '../../../api/Auth';
import { FetchUserAttributesOutput } from 'aws-amplify/auth';

const AuthContext = React.createContext<{ user: FetchUserAttributesOutput | null, isValidatingAuth: boolean, containGroup: any, login: any, logout: any, getAttribute: any }>({
    user: null,
    isValidatingAuth: false,
    login: () => { },
    logout: () => { },
    getAttribute: () => { },
    containGroup: () => { }
});

export const AuthProvider = ({ children }: { children: any }) => {
    const [user, setUser] = React.useState<any>(null);
    const [isValidating, setValidating] = React.useState<any>(null);

    const login = async (email: string, password: string) => {        
        await Auth.SignIn(email, password, true);        
        const user = await Auth.GetSession();
        sessionStorage.setItem("user", JSON.stringify(user));
        setUser(user);
    };

    const logout = async () => {
        await Auth.SignOut();
        sessionStorage.clear();
        setUser(null)
    };

    const getAttribute = (attribute: string) => {
        if (sessionStorage.getItem("user")) {            
            const user = JSON.parse(sessionStorage.getItem("user") as string);
            if(user.tokens?.idToken?.payload[attribute])
                return (user.tokens?.idToken?.payload[attribute]);
        }
        return undefined;
    };

    const containGroup = (group: string) => {
        const groups = getAttribute("cognito:groups");
        if(groups){
            return (groups.indexOf(group));
        }
        return false;
    }

    React.useEffect(() => {
        (async () => {
            try {
                setValidating(true);

                if (sessionStorage.getItem("user"))
                    Auth.GetSession()
                        .catch(() => {
                            logout();
                            sessionStorage.clear();
                            if (window.location.pathname !== '/')
                                window.location.href = '/'
                        });

                let user = null;
                if (!sessionStorage.getItem("user")) {
                    user = await Auth.GetSession();
                    sessionStorage.setItem("user", JSON.stringify(user));
                } else
                    user = JSON.parse(sessionStorage.getItem("user") as string);

                if (user)
                    setUser(user);
            } catch (error) {
                //console.log(error);
            } finally {
                setValidating(false);
            }

        })();
    }, []);


    return (
        <AuthContext.Provider value={{ user, isValidatingAuth: isValidating, login, containGroup, logout, getAttribute }}>
            {children}
        </AuthContext.Provider>
    );
};

export const useAuth = () => React.useContext(AuthContext);