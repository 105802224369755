import { PhotoIcon, UserCircleIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { Button, TextField, TextArea, Label, AlertMessage, IAlertMessage, TypeAlertMessage } from "@sixma/web-ui";
import { useForm } from "react-hook-form";
import FieldValidator from "../molecules/FieldValidator";
import TextAreaValidator from "../molecules/TextAreaValidator";
import { useRef, useState } from "react";
import { useAuth } from "../../../domains/auth/providers/Auth";
import { useHoursStore } from "../../../store/HoursStore";
import moment from "moment";
import { useMessageStore } from "../../../store/MessageStore";
import { useAlertMessage } from "../atoms/AlertMessageGlobal/AlertMessageGlobal";


function Register() {

    const { show } = useAlertMessage();

    const { register, handleSubmit, reset,
        formState: { errors }, } = useForm({
            defaultValues: {
                created: moment().format("YYYY-MM-DD")
            }
        })
    const { enqueue } = useMessageStore(state => state);
    const create = useHoursStore(state => state.createHour);

    const { getAttribute } = useAuth();
    const sRef = useRef<IAlertMessage>();

    const [loading, setLoading] = useState(false);

    const { t } = useTranslation();


    const onSubmit = async (data: any) => {
        try {
            setLoading(true);
            await create(data.description, data.hours, data.created, getAttribute("cognito:username"));
            show(t("Register.successMessage"), TypeAlertMessage.success)
            reset();
        } catch (error: any) {
            console.log(error);
        } finally {
            setLoading(false);
        }
    }

    return (

        <>

            <div className="mt-2  h-full">
                <div className="mx-auto   px-0 sm:px-2 h-full ">

                    <h1 className="text-2xl font-bold">{t("Register.title")}</h1>

                    <div className=" bg-white  mt-6     dark:border-gray-700   dark:bg-gray-800  min-w-full  align-middle   rounded-lg   overflow-hidden  px-8 py-6">


                        <form onSubmit={handleSubmit(onSubmit)} >
                            <div className="space-y-12">
                                <div className="border-b border-gray-900/10 pb-12">

                                    <div className="  grid grid-cols-1 gap-x-8 gap-y-8 sm:grid-cols-3">



                                        <FieldValidator
                                            errors={errors} labelText={t("Register.hours")} name="hours" register={register} type="number" validationSchema={{
                                                required: t("Register.hoursErrorRequired")
                                            }} placeholder={t("Register.hourPlaceholder")} />




                                        <FieldValidator
                                            errors={errors} labelText={t("Register.created")} name="created" register={register} type="date" validationSchema={{
                                                required: t("Register.createdErrorRequired"),
                                                max: { value: moment().format("YYYY-MM-DD"), message: t("Register.createdErrorMax") }
                                            }} placeholder={t("Register.createdPlaceholder")} />

                                        <div className="col-span-full">
                                            <TextAreaValidator
                                                errors={errors}
                                                labelText={t("Register.description")} name="description" register={register} rows={2} validationSchema={{
                                                    required: t("Register.descriptionErrorRequired")
                                                }
                                                } placeholder={t("Register.descriptionPlaceholder")}
                                            ></TextAreaValidator>
                                        </div>


                                    </div>
                                </div>


                            </div>

                            <div className="mt-6 flex items-center justify-end gap-x-6">
                                <Button type="submit"
                                    isLoading={loading}
                                    disabled={loading}
                                    className="px-3 py-2 justify-center items-center bg-sixma-2 disabled:bg-sixma-2/60 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none text-white disabled:text-white text-sm">{t("Register.saveButton")}</Button>
                            </div>
                        </form>

                    </div>

                </div>
            </div>
        </>
    );
}

export default Register;

