
import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
} from 'react-router-dom';
import Layout from '../shared/Layout';
import Login from '../components/pages/Login';
import NoPage from '../shared/NoPage';
import Dashboard from '../components/UI/templates/Dashboard';
import MyRegister from '../components/UI/templates/MyRegister';
import Register from '../components/UI/templates/Register';
import Main from '../components/pages/Main';
import { AuthenticationGuard } from './components/AuthenticationGuard';
import AccountRegister from '../components/pages/account/Register';
import AccountRecoverPassword from '../components/pages/account/RecoverPassword';
import AccountChangePassword from '../components/pages/account/ChangePassword';
import AdminRegisters from '../components/UI/templates/admin/AdminRegisters';

const routes = createRoutesFromElements(
  <Route path="/" element={<Layout />}>



    {/* Protect route based on authentication */}
    <Route element={<AuthenticationGuard />}>

      <Route path="main" element={<Main />} >
        <Route index element={<Dashboard />} />
        <Route path="dashboard" element={<Dashboard />} />
        <Route path="register" element={<Register />} />
        <Route path="my-register" element={<MyRegister />} />
        <Route path="admin">
          <Route path="registers" element={<AdminRegisters></AdminRegisters>}></Route>
        </Route>
      </Route>

      <Route path="account" >
        <Route path="change-password" element={<AccountChangePassword />} />
      </Route>



    </Route>

    <Route element={<AuthenticationGuard guardType="unauthenticated" />}>
      <Route index element={<Login />} />
      <Route path="account">
        <Route path="register" element={<AccountRegister />} />
        <Route path="recover-password" element={<AccountRecoverPassword />} />
      </Route>

    </Route>

    {/* Login page in case unauthenticated */}


    <Route path="*" element={<NoPage />} />

  </Route>
);

export const router = createBrowserRouter(routes);