import { CalendarDaysIcon, ClockIcon, CogIcon, PencilIcon, PresentationChartBarIcon, RectangleGroupIcon, RectangleStackIcon, TableCellsIcon, VariableIcon } from "@heroicons/react/24/outline";
import { ChartBarIcon } from "@heroicons/react/24/solid";

import Chart from "react-apexcharts";
import Card from "../organisms/Card";
import { useTranslation } from "react-i18next";
import { useEffect, useState } from "react";
import { useHoursStore } from "../../../store/HoursStore";
import { Hour } from "../../../models/Hour";
import moment from "moment";
import { useAuth } from "../../../domains/auth/providers/Auth";

function Dashboard() {

    const { t } = useTranslation();
    const { getAttribute } = useAuth();
    const { listHours } = useHoursStore(state => state);
    const hours = useHoursStore(state => state.hours);
    const [loading, setLoading] = useState(false);


    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                await listHours(
                    moment().startOf('month').format("YYYY-MM-DD"),
                    moment().toISOString(),
                    getAttribute("cognito:username"));
            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false);
            }
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    function getLastHours() {
        if (hours && hours.length > 0) {
            return getOrderedReversed().slice(0, 6);
        }
        return [];
    }

    function getAccumulatedHours() {
        if (hours && hours.length > 0) {
            return hours.map(hour => hour.hours)
                .reduce((partialSum, a) => partialSum + a, 0);
        }
        return 0;
    }

    function getAvgHours() {
        if (hours && hours.length > 0) {
            const hoursGroupped = getGroupped();

            const groups = hoursGroupped.size;
            let sumHours = 0;
            hoursGroupped.forEach((group: any[]) => {
                group.forEach((subgroup: any) => {
                    sumHours += subgroup.hours
                });
            })

            return Math.round(sumHours / groups);

        }
        return 0;
    }

    function getGroupped(): any {
        if (hours && hours.length > 0) {
            return getOrdered().reduce(
                (entryMap, e) => entryMap.set(moment(e.created).format("YYYY-MM-DD"), [...entryMap.get(moment(e.created).format("YYYY-MM-DD")) || [], e]),
                new Map()
            );
        }

        return [];
    }

    function getHoursToday(): { hoursRegistered: number, hoursTotal: number } {
        if (hours && hours.length > 0) {

            const hoursRegisteredToday = hours.filter(hour => moment(hour.created).format("YYYY-MM-DD") == moment().format("YYYY-MM-DD"))
                .map(hour => hour.hours);
            return { hoursRegistered: hoursRegisteredToday.reduce((partialSum, a) => partialSum + a, 0), hoursTotal: 8 }
        }
        return { hoursRegistered: 0, hoursTotal: 8 }
    }

    function getOrderedReversed() {
        if (hours && hours.length > 0) {
            return hours.sort((a, b) => {
                var diff = moment(a.created).diff(moment(b.created));
                return diff;
            }).reverse();
        }
        return [];
    }



    function getOrdered() {
        if (hours && hours.length > 0) {
            return hours.sort((a, b) => {
                var diff = moment(a.created).diff(moment(b.created));
                return diff;
            });
        }
        return [];
    }



    function getListGroupped() {

        if (hours && hours.length > 0) {
            let dates: any[] = [];
            let hours: any[] = [];
            const groupped = getGroupped();
            groupped.forEach((element: any, key: any) => {
                hours.push(element.map((hour: { hours: any; }) => hour.hours).reduce((partialSum: any, a: any) => partialSum + a, 0))
                dates.push(moment(key).format("DD-MMM"))
            });
            return { dates, hours }
        }
        return { dates: [], hours: [] }
    }


    const options = {
        chart: {
            width: "100%",
            toolbar: {
                show: false
            },

        },
        fill: {
            type: "gradient",
            gradient: {
                opacityFrom: 0.55,
                opacityTo: 0,
                shade: "#1C64F2",
                gradientToColors: ["#1C64F2"],
            },
        },
        tooltip: {
            enabled: true,
            x: {
                show: false,
            },
        },
        dataLabels: {
            enabled: false,
        },
        stroke: {
            width: 4,
        },
        grid: {
            show: true,
            strokeDashArray: 4,
            padding: {
                left: 10,
                right: 0,
                top: 0
            },
        },
        series: [
            {
                name: t("Dashboard.registerHoursChartLeyend"),
                data: getListGroupped().hours,
                color: "#2D358C",
            },
        ],
        xaxis: {
            categories: getListGroupped().dates,
            labels: {
                show: true,
                style: {
                    colors: '#747474'
                }
            },
            axisBorder: {
                show: false,
            },
            axisTicks: {
                show: false,
            },

        },
        yaxis: {
            show: true,
            labels: {
                show: true,
                style: {
                    colors: '#747474'
                }
            },
        },
    }


    return (
        <div className="mt-2  h-full">
            <div className="mx-auto   px-0 sm:px-2 h-full ">

                <h1 className="text-2xl font-bold">{t("Dashboard.title")}</h1>

                <div className="mt-6 grid grid-cols-1 gap-x-6 gap-y-6 xl:grid-cols-3 xl:gap-x-6">

                    <Card
                        title={t("Dashboard.registerHours")}
                        icon={<ClockIcon className="text-white h-12" />}
                        subText={<span>{(moment().format("DD-MM-YYYY") == moment().format("DD-MM-YYYY") ? t("Dashboard.today") : t("Dashboard.the")) + moment().format("DD-MM-YYYY")}</span>}
                        colorIcon="bg-sixma-2-600"
                        loading={loading}
                        text={<span><span className="font-normal text-2xl">{getHoursToday().hoursRegistered} {/* / </span><span className="font-bold text-2xl">{getHoursToday().hoursTotal} */}</span> {t("Dashboard.hh")}</span>}
                    ></Card>


                    <Card
                        title={t("Dashboard.accumulatedHours")}
                        icon={<RectangleStackIcon className="text-white h-12" />}
                        subText={<span>{t("Dashboard.toDate", { to: moment().format("DD-MM-YYYY") })}</span>}
                        colorIcon="bg-sixma-1"
                        loading={loading}
                        text={<span><span className="font-bold text-2xl">{getAccumulatedHours()}</span> {t("Dashboard.hh")}</span>}
                    ></Card>


                    <Card
                        title={t("Dashboard.dairyAVG")}
                        icon={<VariableIcon className="text-white h-12" />}
                        subText={<span>{t("Dashboard.toDate", { to: moment().format("DD-MM-YYYY") })}</span>}
                        colorIcon="bg-pink-600"
                        loading={loading}
                        text={<span><span className="font-bold text-2xl">{getAvgHours()}</span> {t("Dashboard.hh")}</span>}
                    ></Card>



                </div>

                <div className="grid grid-cols-1  gap-y-6 xl:grid-cols-5 xl:gap-x-6 mt-6  ">

                    <div className="col-span-3">

                        <div className=" bg-white   max-h-96  rounded-lg   dark:border-gray-700  px-5 py-6 dark:bg-gray-800">

                            <div className="grid grid-cols-3 items-center">
                                <div className="text-md font-bold  col-span-2 ">{t("Dashboard.registerHoursUntilDay")}</div>
                                <div className="  col-start-3  text-right text-gray-500 text-xs">{t("Dashboard.toDate", { to: moment().format("DD-MM-YYYY") })}</div>
                            </div>

                            {!loading && getListGroupped().hours.length > 0 && <Chart
                                options={options}
                                series={options.series}
                                height={320}
                                type="area"
                            />}

                            {!loading && getListGroupped().hours.length == 0 && <div className="lg:h-[320px] flex-wrap text-center m-auto py-8 items-center text-gray-500">
                                <div className=" text-center"><ChartBarIcon className="h-44 m-auto py-4 text-gray-300"></ChartBarIcon>{t("Dashboard.withoutRows")}</div>
                            </div>}

                            {loading && <div className="lg:h-[320px] animate-pulse rounded-md bg-gray-200">
                                <ChartBarIcon className="h-64 m-auto py-4 text-white"></ChartBarIcon>
                                <div className="h-3 bg-white rounded-md dark:bg-gray-700   w-56 m-auto"></div>
                                <div className="h-3 bg-white rounded-md dark:bg-gray-700  mt-2  w-40 m-auto"></div>
                            </div>}
                        </div>

                    </div>

                    <div className="col-span-2 bg-white   relative   max-h-96 dark:border-gray-700   dark:bg-gray-800  min-w-full  align-middle   rounded-lg  h-96 overflow-hidden">

                        {loading && <div className="  w-full h-full absolute  opacity-75 z-50 text-center flex  items-center px-5 py-8 top-8 ">
                            <div role="status" className=" w-full h-full  animate-pulse grid-cols-3 grid gap-x-4">
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-1"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-1"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-1"></div>
                                <div className="h-0.5 bg-gray-200 dark:bg-gray-700 w-full col-span-3 mb-1"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>

                                <span className="sr-only">Loading...</span>
                            </div>
                        </div>}

                        <div className="  px-5 py-6">
                            <div className="  mb-3">
                                <div className="grid grid-cols-3 items-center ">
                                    <div className="text-md font-bold  col-span-2   ">{t("Dashboard.lastTaskRegistered")}</div>
                                    <div className="  col-start-3  text-right text-gray-500 text-xs  ">{t("Dashboard.toDate", { to: moment().format("DD-MM-YYYY") })}</div>
                                </div>
                            </div>
                            <table className="min-w-full mt-3  h-36">
                                {getLastHours().length > 0 && !loading && (<thead className="bg-gray-50  sticky top-0">
                                    <tr>
                                        <th scope="col" className=" w-36  py-2  px-2   text-left text-[11px] font-semibold text-gray-600  uppercase">
                                            {t("Dashboard.tableTaskRegisterdDate")}
                                        </th>
                                        <th scope="col" className=" w-10  px-2 py-2   text-[11px] text-center font-semibold text-gray-600 uppercase">
                                            {t("Dashboard.tableTaskRegisterdHours")}
                                        </th>
                                        <th scope="col" className=" hidden sm:table-cell px-2 py-2 text-center text-[11px] font-semibold text-gray-600 uppercase">
                                            {t("Dashboard.tableTaskRegisterdTask")}
                                        </th>
                                    </tr>
                                </thead>)}
                                <tbody>
                                    {getLastHours() && !loading && (getLastHours().map((hour: Hour) => (
                                        <tr key={hour.id} className="even:bg-gray-50">
                                            <td className="whitespace-nowrap px-3 py-3 text-xs  text-gray-900 ">
                                                <div className="sm:hidden text-wrap text-[11px] mt-1">
                                                    {hour.description}
                                                </div>
                                                <div className="text-[10px] sm:text-xs mt-2 sm:mt-0 font-bold">
                                                    <CalendarDaysIcon className="h-4 sm:h-5 inline-block align-middle text-gray-500" />  <span className="align-middle"> {moment(hour.created).format("DD-MM-YYYY")}</span></div>

                                            </td>
                                            <td className="whitespace-nowrap px-3 py-3 text-xs text-center text-gray-900">
                                                {hour.hours} <span className="text-[10px]">{t("Dashboard.hh")}</span>
                                            </td>
                                            <td className=" hidden sm:table-cell px-1 py-3 text-gray-900 text-xs">
                                                <div className="text-center ">
                                                    <div className="sm:w-96 md:w-96 lg:w-96 xl:w-28 2xl:w-40 3xl:w-64 4xl:w-auto m-auto text-nowrap text-ellipsis overflow-hidden ">{hour.description}</div>
                                                </div>
                                            </td>
                                        </tr>
                                    )))}
                                    {getLastHours().length == 0 && !loading && (
                                        <tr>
                                            <td
                                                className="whitespace-nowrap   text-md text-center text-gray-500"
                                                colSpan={3}>
                                                <div className="mt-2">                                                    
                                                    <TableCellsIcon className="h-44 m-auto    text-gray-300"></TableCellsIcon>
                                                    {t("Dashboard.withoutRows")}
                                                </div>
                                            </td>
                                        </tr>
                                    )}

                                    {/*   {loading && <tr>
                                    <td colSpan={3}>
                                        
                                    </td>
                                </tr>} */}

                                </tbody>
                            </table>
                        </div>
                    </div>





                </div>



            </div>
        </div>);
}

export default Dashboard;