import { generateClient } from 'aws-amplify/api';
import { getHours, listHours } from '../graphql/queries';
import { createHours, deleteHours, updateHours } from '../graphql/mutations';
import moment from 'moment';

const client = generateClient();

const ListHours = async (dateFrom: string, dateTo: string, username?: string, description?: string) => {
    //console.log("list "  + dateFrom +  " -  " + dateTo)

    let filter: any = {}

    filter['created'] = { between: [dateFrom, dateTo] }

    if (username)
        filter["username"] = { eq: username };

    if (description)
        filter["descriptionSearch"] = { contains: description };

    const result = await client.graphql({
        query: listHours,
        variables: {
            filter: filter
        }
    });

    return result?.data?.listHours?.items;
}

const FindHour = async (idHour: string) => {

    const result = await client.graphql({
        query: getHours,
        variables: {
            id: idHour
        }
    });

    return result?.data?.getHours;
}

const UpdateHour = async (id: string, description: string, hours: number, created: string) => {
    await client.graphql({
        query: updateHours,
        variables: {            
            input: {                
                id: id,            
                description: description,
                descriptionSearch: description.toLowerCase(),
                //username: username,
                hours: hours,
                created: moment(created).format()
            }
        }
    })
}


const CreateHour = async (description: string, hours: number, created: string, username: string) => {

    await client.graphql({
        query: createHours,
        variables: {
            input: {
                description: description,
                descriptionSearch: description.toLowerCase(),
                username: username,
                hours: hours,
                created: moment(created).format()
            }            
        }
    })
}

const DeleteHour = async(idHour: string) =>{
    await client.graphql({
        query: deleteHours,
        variables: {
            input: {
                id: idHour
            }            
        }
    })
}

const Hours = {
    DeleteHour,
    ListHours,
    CreateHour,
    FindHour,
    UpdateHour
};

export default Hours; 