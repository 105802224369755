import { ChevronDownIcon, ClockIcon, PencilSquareIcon, XMarkIcon, ArrowLongLeftIcon, ArrowLongRightIcon, CalendarDaysIcon } from "@heroicons/react/24/outline";
import { TextField, LinkIcon, Label, Button, Select, ElementType, TypeAlertMessage } from "@sixma/web-ui";

import { Fragment, useEffect, useRef, useState } from "react";
import { useTranslation } from "react-i18next";
import { useHoursStore } from "../../../../store/HoursStore";
import moment from "moment";
import Auth from "../../../../api/Auth";
import { User } from "../../../../models/User";
import { Hour } from "../../../../models/Hour";
import { useForm } from "react-hook-form";
import { EditHour, IEditHour } from "../EditHour";
import { Confirm, IConfirm } from "../../organisms/Confirm";
import { useAlertMessage } from "../../atoms/AlertMessageGlobal/AlertMessageGlobal";

function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}
export default function AdminRegisters() {

    const { show } = useAlertMessage();
    const { listHours, hours, deleteHour } = useHoursStore(state => state);
    const [loading, setLoading] = useState(false);
    const [users, setUsers] = useState<Array<User>>();
    const [userSearch, setUserSearch] = useState("");
    const [usersSelect, setUserSelect] = useState<Array<ElementType>>();

    const editHourRef = useRef<IEditHour>();
    const confirmRef = useRef<IConfirm>();

    const { register, handleSubmit, reset,
        formState: { errors }, } = useForm({
            defaultValues: {
                from: moment().startOf('month').format("YYYY-MM-DD"),
                to: moment().format("YYYY-MM-DD"),
                user: ""
            }
        })

    useEffect(() => {
        (async () => {
            try {
                setLoading(true);
                await listHours(
                    moment().startOf('month').format("YYYY-MM-DD"),
                    moment().toISOString());
                const users = await Auth.ListUsers();
                setUsers(users);
                setUserSelect([{ description: t("AdminRegister.allOptions"), value: "" }].concat(users?.map<ElementType>(user => ({ description: user.givenName + "  " + user.familyName, value: user.username }))));

            } catch (error) {
                console.log(error)
            } finally {
                setLoading(false);
            }
        })();
        return () => {
            // this now gets called when the component unmounts
        };
    }, []);

    function getUserHours() {
        if (hours && users) {
            let userHours: any = [];

            users.filter(user => (userSearch != "" && user.username == userSearch) || userSearch == "").forEach(user => {
                userHours.push({
                    user: user, hours: hours.filter((hour: Hour) => hour.username == user.username).sort((a, b) => {
                        var diff = moment(a.created).diff(moment(b.created));
                        return diff;
                    })
                });
            });
            return userHours.sort((a: any, b: any) => {
                var diff = a.user.givenName > b.user.givenName;
                return diff;
            });
        }
        return [];
    }

    const onSubmit = async (data: any) => {
        try {
            setLoading(true);
            setUserSearch(data.user);
            await listHours(
                data.from,
                moment(data.to).toISOString(),
                data.user,
                data.description);

        } catch (error: any) {
            /*if (error.toString().indexOf("UserNotFoundException") !== -1)
                alertRef.current?.show(t("Login.userNotFoundError"), TypeAlertMessage.error);*/

        } finally {
            //setValidating(false);
            setLoading(false);
        }
    }

    async function deleteHourSelected(id: any) {
        try {
            setLoading(true);

            await deleteHour(id);
            show(t("AdminRegister.messageDeleted"), TypeAlertMessage.success)
            handleSubmit(onSubmit)()
        } catch (error: any) {
            /*if (error.toString().indexOf("UserNotFoundException") !== -1)
                alertRef.current?.show(t("Login.userNotFoundError"), TypeAlertMessage.error);*/

        } finally {
            //setValidating(false);
            setLoading(false);
        }
    }


    const { t } = useTranslation();

    return (

        <>
            <Confirm ref={confirmRef} text={t("AdminRegister.messageQueryDeleted")} title={t("AdminRegister.messageDeletedTitle")} acceptConfirm={(data: any) => deleteHourSelected(data.id)}></Confirm>
            <EditHour ref={editHourRef} onUpdate={(data: any) => { handleSubmit(onSubmit)() }}></EditHour>
            <div className="mt-2  h-full">
                <div className="mx-auto   px-0 sm:px-2 h-full ">

                    <h1 className="text-2xl font-bold">{t("AdminRegister.title")}</h1>

                    <div className=" bg-white  mt-6     dark:border-gray-700   dark:bg-gray-800  min-w-full  align-middle   rounded-lg   overflow-hidden  px-5 py-6">

                        <form className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-x-6 items-end" onSubmit={handleSubmit(onSubmit)}>

                            <div className="mt-1">
                                <Label htmlFor="user">{t("AdminRegister.user")}</Label>
                                <div className="mt-1">

                                    <Select id="user" name="user" register={register} validationSchema={{}}
                                        className="w-full py-1.5 sm:text-sm sm:leading-6 focus:ring-1 focus:ring-inset focus:ring-sixma-2"
                                        items={usersSelect}></Select>
                                </div>
                            </div>


                            <div className="mt-1">
                                <Label htmlFor="from">{t("AdminRegister.from")}</Label>
                                <div className="mt-1">
                                    <TextField
                                        register={register} validationSchema={{
                                            required: t("AdminRegister.fromErrorRequired")
                                        }} className="w-full py-1.5 sm:text-sm sm:leading-6 focus:ring-1 focus:ring-inset focus:ring-sixma-2" id="from" name="from" type="date" placeholder={t("AdminRegister.fromPlaceholder")}></TextField>
                                </div>
                            </div>

                            <div className="mt-1">
                                <Label htmlFor="to">{t("AdminRegister.to")}</Label>
                                <div className="mt-1">
                                    <TextField
                                        register={register} validationSchema={{
                                            required: t("AdminRegister.toErrorRequired")
                                        }} className="w-full py-1.5 sm:text-sm sm:leading-6 focus:ring-1 focus:ring-inset focus:ring-sixma-2" id="to" name="to" type="date" placeholder={t("AdminRegister.toPlaceholder")}></TextField>
                                </div>
                            </div>

                            <div className="mt-1">

                                <Label htmlFor="description">{t("AdminRegister.description")}</Label>
                                <div className="mt-1">
                                    <TextField
                                        register={register} validationSchema={{}}
                                        className="w-full py-1.5 sm:text-sm sm:leading-6 focus:ring-1 focus:ring-inset focus:ring-sixma-2"
                                        id="description"
                                        name="description"
                                        type="text"
                                        placeholder={t("AdminRegister.descriptionPlaceholder")}
                                    ></TextField>
                                </div>

                            </div>
                            <div className="mt-4 sm:mt-2  flex justify-end sm:justify-start   ">


                                <Button type="submit" className="   px-3 py-2  bg-sixma-2 disabled:bg-sixma-2/60 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none text-white disabled:text-white text-sm">{t("AdminRegister.findButton")}</Button>
                            </div>
                        </form>


                        <div className="mt-4 -mx-4 -my-2 overflow-x-auto sm:-mx-6 lg:-mx-8">
                            <div className="inline-block min-w-full py-2 align-middle px-5 lg:px-8">
                                {loading && <div className="  w-full h-full    opacity-75 z-50 text-center flex  items-center px-5 py-8 top-8 ">
                                    <div role="status" className=" w-full h-full  animate-pulse grid-cols-5 grid gap-x-4 gap-y-2">
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-1"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-1"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-1"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-1"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-1"></div>
                                        <div className="h-0.5 bg-gray-200 dark:bg-gray-700 w-full col-span-5 mb-1"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>
                                        <div className="h-3 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-2.5"></div>

                                        <span className="sr-only">Loading...</span>
                                    </div>
                                </div>}
                                <table className="min-w-full">
                                    {!loading && (<thead className="bg-gray-50">
                                        <tr>
                                            <th scope="col" className="py-2  px-2   text-left text-[11px] font-semibold text-gray-600  uppercase">
                                                {t("AdminRegister.nameHeader")}
                                            </th>
                                            <th scope="col" className="hidden sm:table-cell py-2  px-2   text-center text-[11px] font-semibold text-gray-600  uppercase">
                                                {t("AdminRegister.descriptionHeader")}
                                            </th>
                                            <th scope="col" className="py-2  px-2   text-center text-[11px] font-semibold text-gray-600  uppercase">
                                                {t("AdminRegister.hoursHeader")}
                                            </th>
                                            <th scope="col" className="hidden sm:table-cell py-2  px-2   text-center text-[11px] font-semibold text-gray-600  uppercase">
                                                {t("AdminRegister.registerOriginalDateHeader")}
                                            </th>
                                            <th scope="col" className="relative py-2  px-2   text-left text-[11px] font-semibold text-gray-600  uppercase">
                                                <span className="sr-only">Edit</span>
                                            </th>
                                        </tr>
                                    </thead>)}
                                    <tbody className="bg-white">
                                        {!loading && getUserHours() && getUserHours().map((userHour: any) => (
                                            <Fragment key={userHour.user.username}>
                                                <tr className="border-t border-gray-200">
                                                    <th
                                                        colSpan={4}
                                                        scope="colgroup"
                                                        className="bg-gray-50 py-2 pl-4 pr-3 text-left text-sm font-semibold text-gray-900 sm:pl-3"
                                                    >
                                                        {userHour.user.givenName + " " + userHour.user.familyName}
                                                        <div className="sm:hidden text-right">
                                                            Total Horas: {userHour.hours.map((hour: any) => hour.hours)
                                                                .reduce((partialSum: any, a: any) => partialSum + a, 0)} <span className="text-[10px]">{t("AdminRegister.hh")}</span>
                                                        </div>
                                                    </th>
                                                    <th
                                                        className="hidden sm:table-cell bg-gray-50 py-2 pl-4 pr-3  text-sm font-semibold text-gray-900 sm:pl-3 text-right">
                                                        {t("AdminRegister.totalHours")} {userHour.hours.map((hour: any) => hour.hours)
                                                            .reduce((partialSum: any, a: any) => partialSum + a, 0)} <span className="text-[10px]">{t("AdminRegister.hh")}</span>
                                                    </th>
                                                </tr>
                                                {(!userHour.hours || userHour.hours.length == 0) && (
                                                    <tr>
                                                        <td colSpan={5} className="  py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-3">No tiene horas cargadas</td>
                                                    </tr>
                                                )}
                                                {userHour.hours.length > 0 && userHour.hours.map((hour: any, hourIdx: any) => (
                                                    <tr
                                                        key={hour.id}
                                                        className={classNames(hourIdx === 0 ? 'border-gray-300' : 'border-gray-200', 'border-t')}
                                                    >
                                                        <td className="  py-4 pl-4 pr-3 text-xs font-medium text-gray-900 sm:pl-3">
                                                            <div className="sm:hidden text-wrap text-[11px] mt-1">
                                                                {hour.description}
                                                            </div>
                                                            <div className="text-[10px] sm:text-xs mt-2 sm:mt-0 font-bold">
                                                                <CalendarDaysIcon className="h-4 sm:h-5 inline-block align-middle text-gray-500" />  <span className="align-middle"> {moment(hour.created).format("DD-MM-YYYY")}</span></div>
                                                        </td>
                                                        <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-xs text-gray-500">
                                                            <div className="text-center ">
                                                                <div className="sm:w-96 md:w-96 lg:w-96 xl:w-28 2xl:w-40 3xl:w-64 4xl:w-auto m-auto text-nowrap text-ellipsis overflow-hidden ">{hour.description}</div>
                                                            </div>
                                                        </td>
                                                        <td className="whitespace-nowrap px-3 py-4 text-xs text-center text-gray-500">{hour.hours} <span className="text-[10px]">{t("Dashboard.hh")}</span></td>
                                                        <td className="hidden sm:table-cell whitespace-nowrap px-3 py-4 text-xs text-gray-500 text-center">{moment(hour.createdAt).format("DD-MM-YYYY HH:mm")}</td>
                                                        <td className="relative   py-4 pl-3 pr-4 text-right text-xs font-medium sm:pr-3">
                                                            <div className="inline-block px-3">
                                                                <LinkIcon to=""
                                                                    onClick={() => editHourRef?.current?.show(hour.id)}
                                                                    icon={<PencilSquareIcon className="h-5 inline-block" />} className="text-sixma-1 hover:text-sixma-1-500">{t("MyRegister.editLink")}</LinkIcon>
                                                            </div>
                                                            <div className="inline-block px-3">
                                                                <LinkIcon to=""
                                                                    onClick={() => confirmRef?.current?.show(hour)}
                                                                    icon={<XMarkIcon className="h-5 inline-block" />} className="text-sixma-1 hover:text-sixma-1-500">{t("MyRegister.deleteLink")}</LinkIcon>
                                                            </div>
                                                        </td>
                                                    </tr>
                                                ))}
                                            </Fragment>
                                        ))}
                                    </tbody>
                                </table>
                            </div>
                        </div>





                    </div>
                </div >
            </div ></>)
}