import { Fragment, useEffect, useState } from 'react'
import { Dialog, Transition } from '@headlessui/react'
import {
    ChartPieIcon,
    Cog6ToothIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
    ClockIcon,
    FolderArrowDownIcon,
    RectangleGroupIcon,
    UserGroupIcon,
    BriefcaseIcon
} from '@heroicons/react/24/outline'


import { useSidebarStore } from '../../../store/SidebarStore'
import { Link, useLocation } from 'react-router-dom'
import { useTranslation } from 'react-i18next'
import Logo from '../atoms/Logo'
import { useAuth } from '../../../domains/auth/providers/Auth'

const navigation = [
    { name: 'Nav.dashboard', href: '/main', icon: RectangleGroupIcon },
    { name: 'Nav.register', href: '/main/register', icon: ClockIcon },
    /* { name: 'Nav.myRegister', href: '/main/my-register', icon: FolderArrowDownIcon }, */

]






function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}


function SideBar() {

    const { t } = useTranslation();
    const location = useLocation();
    const sidebarOpen = useSidebarStore(state => state.open)
    const setOpen = useSidebarStore(state => state.setOpen);

    function SideBarLinks() {


        const { containGroup } = useAuth();

        let adminNavigation = [
            /* ADMIN */
        /*     { name: 'Nav.users', href: '#', icon: UsersIcon },
            { name: 'Nav.teams', href: '#', icon: UserGroupIcon },
            { name: 'Nav.projects', href: '#', icon: BriefcaseIcon }, */
            { name: 'Nav.registers', href: '/main/admin/registers', icon: FolderIcon },                                    
           /*  { name: 'Nav.reports', href: '#', icon: ChartPieIcon }, */
        ]

        useEffect(() => { 
        }, []);

        return (
            <div className="flex grow flex-col gap-y-5 overflow-y-auto bg-sixma-2 px-6 pb-4 ">
                <div className="flex h-16 shrink-0 items-center">
                    <Logo isWhite={true} h="h-8"></Logo>
                </div>
                <nav className="flex flex-1 flex-col">
                    <ul role="list" className="flex flex-1 flex-col gap-y-7">
                        <li>
                            <ul role="list" className="-mx-2 space-y-1">
                                {navigation.map((item) => (
                                    <li key={item.name}>
                                        <Link
                                            to={item.href}
                                            onClick={() => setOpen(false)}
                                            className={classNames(
                                                item.href == location.pathname
                                                    ? 'bg-sixma-2-700 text-white'
                                                    : 'text-indigo-200 hover:text-white hover:bg-sixma-2-700',
                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                            )}
                                        >
                                            <item.icon
                                                className='text-white h-6 w-6 shrink-0'

                                                aria-hidden="true"
                                            />
                                            {t(item.name)}
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </li>
                        {(containGroup("Administrators") != -1 && <li>
                            <div className="text-xs font-semibold leading-6 text-indigo-200">{t("Nav.administration")}</div>
                            <ul role="list" className="-mx-2 mt-2 space-y-1">
                                {adminNavigation.map((item) => (
                                    <li key={item.name}>
                                        <Link
                                            to={item.href}
                                            onClick={() => setOpen(false)}
                                            className={classNames(
                                                item.href == location.pathname
                                                    ? 'bg-sixma-2-700 text-white'
                                                    : 'text-indigo-200 hover:text-white hover:bg-sixma-2-700',
                                                'group flex gap-x-3 rounded-md p-2 text-sm leading-6 font-semibold'
                                            )}
                                        >
                                            <item.icon
                                                className='text-white  h-6 w-6 shrink-0'

                                                aria-hidden="true"
                                            />
                                            <span className="truncate">{t(item.name)}</span>
                                        </Link>
                                    </li>
                                ))}
                            </ul>
                        </li>)}
                       {/*  <li className="mt-auto">
                            <Link
                                to="#"
                                className="group -mx-2 flex gap-x-3 rounded-md p-2 text-sm font-semibold leading-6 text-indigo-200 hover:bg-sixma-2-700 hover:text-white"
                            >
                                <Cog6ToothIcon
                                    className="h-6 w-6 shrink-0 text-indigo-200 group-hover:text-white"
                                    aria-hidden="true"
                                />
                                {t("Nav.settings")}
                            </Link>
                        </li> */}
                    </ul>
                </nav>
            </div>
        );
    }

    return (
        <>
            <Transition.Root show={sidebarOpen} as={Fragment}>
                <Dialog as="div" className="relative z-50 lg:hidden" onClose={setOpen}>
                    <Transition.Child
                        as={Fragment}
                        enter="transition-opacity ease-linear duration-300"
                        enterFrom="opacity-0"
                        enterTo="opacity-100"
                        leave="transition-opacity ease-linear duration-300"
                        leaveFrom="opacity-100"
                        leaveTo="opacity-0"
                    >
                        <div className="fixed inset-0 bg-gray-900/80" />
                    </Transition.Child>

                    <div className="fixed inset-0 flex">
                        <Transition.Child
                            as={Fragment}
                            enter="transition ease-in-out duration-300 transform"
                            enterFrom="-translate-x-full"
                            enterTo="translate-x-0"
                            leave="transition ease-in-out duration-300 transform"
                            leaveFrom="translate-x-0"
                            leaveTo="-translate-x-full"
                        >
                            <Dialog.Panel className="relative mr-16 flex w-full max-w-xs flex-1">
                                <Transition.Child
                                    as={Fragment}
                                    enter="ease-in-out duration-300"
                                    enterFrom="opacity-0"
                                    enterTo="opacity-100"
                                    leave="ease-in-out duration-300"
                                    leaveFrom="opacity-100"
                                    leaveTo="opacity-0"
                                >
                                    <div className="absolute left-full top-0 flex w-16 justify-center pt-5">
                                        <button type="button" className="-m-2.5 p-2.5" onClick={() => setOpen(false)}>
                                            <span className="sr-only">Close sidebar</span>
                                            <XMarkIcon className="h-6 w-6 text-white" aria-hidden="true" />
                                        </button>
                                    </div>
                                </Transition.Child>
                                {/* Sidebar component, swap this element with another sidebar if you like */}
                                <SideBarLinks />
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </Dialog>
            </Transition.Root>

            {/* Static sidebar for desktop */}
            <div className="hidden lg:fixed lg:inset-y-0 lg:z-50 lg:flex lg:w-72 lg:flex-col">
                {/* Sidebar component, swap this element with another sidebar if you like */}
                <SideBarLinks />
            </div></>
    );
}
export default SideBar;