import { Transition, Dialog } from "@headlessui/react";
import { Button } from "@sixma/web-ui";
import { Fragment, forwardRef, useImperativeHandle, useState } from "react";
import { useTranslation } from "react-i18next";

export interface IConfirm {
    show(element: any): void;
    hide(): void;
}

export const Confirm = forwardRef((props: any, ref: any) => {

    const { t } = useTranslation();
    const [element, setElement] = useState();
    let [isOpen, setIsOpen] = useState(false)
    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }

    useImperativeHandle(ref, () => ({

        show(element: any) {            
            setElement(element);
            openModal();
        },
        hide() {
            closeModal();
        }

    }));

    function handleAccept(){
        if(element && props.acceptConfirm)
            props.acceptConfirm(element);
        closeModal();
    }

    return (<>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    {props.title}
                                </Dialog.Title>
                                <div className="text-md text-center my-6">
                                {props.text}
                                </div>

                                <div className="mt-4 flex   items-center justify-center gap-x-3">

                                    <Button type="button"
                                    onClick={() => handleAccept() }
                                    className="   px-3 py-2  bg-sixma-2 disabled:bg-sixma-2/60 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none text-white disabled:text-white text-sm">{t("General.acceptButton")}</Button>
                                    <Button type="button" onClick={closeModal} className="   px-3 py-2  bg-gray-100  disabled:text-white text-sm">{t("General.cancelButton")}</Button>


                                </div>

                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    </>)
});