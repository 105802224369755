import { Dialog, Transition } from "@headlessui/react";
import { Fragment, forwardRef, useEffect, useImperativeHandle, useMemo, useState } from "react";
import { useHoursStore } from "../../../store/HoursStore";
import { useTranslation } from "react-i18next";
import { Button, TypeAlertMessage } from "@sixma/web-ui";
import FieldValidator from "../molecules/FieldValidator";
import TextAreaValidator from "../molecules/TextAreaValidator";
import moment from "moment";
import { useForm } from "react-hook-form";
import { useAlertMessage } from "../atoms/AlertMessageGlobal/AlertMessageGlobal";

export interface IEditHour {
    show(idHour: string): void;
    hide(): void;
}

export const EditHour = forwardRef((props: any, ref: any) => {

    let [isOpen, setIsOpen] = useState(false)
    let [loading, setIsLoading] = useState(false)
    let [updating, setIsUpdating] = useState(false)
    //let [onUpdate, setOnUpdate] = useState<any>();

    const { show } = useAlertMessage();
    const { t } = useTranslation();

    const { findedHour, findHour, updateHour } = useHoursStore(state => state);
    const { register, handleSubmit, reset,
        formState: { errors }, } = useForm({
            defaultValues: useMemo(() => {
                return {
                    hours: findedHour?.hours,
                    created: moment(findedHour?.created).format("YYYY-MM-DD"),
                    description: findedHour?.description
                };
            }, [findedHour])
        })

    useEffect(() => {
        reset({
            hours: findedHour?.hours,
            created: moment(findedHour?.created).format("YYYY-MM-DD"),
            description: findedHour?.description
        });
    }, [findedHour]);

    function closeModal() {
        setIsOpen(false)
    }

    function openModal() {
        setIsOpen(true)
    }


    useImperativeHandle(ref, () => ({

        async show(idHour: string) {
            openModal();
            setIsLoading(true);
            await findHour(idHour);
            setIsLoading(false);
        },
        hide() {
            closeModal();
        }

    }));

    const onSubmit = async (data: any) => {
        try {
            setIsUpdating(true);
            await updateHour(findedHour?.id as string, data.description, data.hours, data.created);
            if (props.onUpdate)
                props.onUpdate(data);

            show(t("EditHour.successMessage"), TypeAlertMessage.success)
            closeModal();
            reset();

        } catch (error: any) {
            console.log(error);
        } finally {
            setIsUpdating(false);
        }
    }


    return (<>
        <Transition appear show={isOpen} as={Fragment}>
            <Dialog as="div" className="relative z-10" onClose={closeModal}>
                <Transition.Child
                    as={Fragment}
                    enter="ease-out duration-300"
                    enterFrom="opacity-0"
                    enterTo="opacity-100"
                    leave="ease-in duration-200"
                    leaveFrom="opacity-100"
                    leaveTo="opacity-0"
                >
                    <div className="fixed inset-0 bg-black/25" />
                </Transition.Child>

                <div className="fixed inset-0 overflow-y-auto">
                    <div className="flex min-h-full items-center justify-center p-4 text-center">
                        <Transition.Child
                            as={Fragment}
                            enter="ease-out duration-300"
                            enterFrom="opacity-0 scale-95"
                            enterTo="opacity-100 scale-100"
                            leave="ease-in duration-200"
                            leaveFrom="opacity-100 scale-100"
                            leaveTo="opacity-0 scale-95"
                        >
                            <Dialog.Panel className="w-full max-w-md transform overflow-hidden rounded-md bg-white p-6 text-left align-middle shadow-xl transition-all">
                                <Dialog.Title
                                    as="h3"
                                    className="text-lg font-medium leading-6 text-gray-900"
                                >
                                    {t("EditHour.title")}
                                </Dialog.Title>
                                {loading && (
                                    <div className="  w-full h-full    opacity-75 z-50 text-center flex  items-center px-5 py-8 top-8 ">
                                        <div role="status" className=" w-full h-full  animate-pulse grid-cols-1 grid gap-x-4 gap-y-2">
                                            <div className="h-5 bg-gray-200 rounded-md dark:bg-gray-700 w-[100px] mb-1"></div>
                                            <div className="h-6 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-3"></div>
                                            <div className="h-5 bg-gray-200 rounded-md dark:bg-gray-700 w-[100px] mb-1"></div>
                                            <div className="h-6 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-3"></div>
                                            <div className="h-5 bg-gray-200 rounded-md dark:bg-gray-700 w-[100px] mb-1"></div>
                                            <div className="h-16 bg-gray-200 rounded-md dark:bg-gray-700 w-full mb-0"></div>

                                            <span className="sr-only">Loading...</span>
                                        </div>
                                    </div>
                                )}

                                <form onSubmit={handleSubmit(onSubmit)}>
                                    {(!loading &&
                                        <div className="mt-2 grid grid-cols-1 gap-y-4 p-4">
                                            {/*      <p className="text-sm text-gray-500">
                                                {JSON.stringify(findedHour)}
                                            </p> */}

                                            <FieldValidator
                                                errors={errors} labelText={t("Register.hours")} name="hours" register={register} type="number" validationSchema={{
                                                    required: t("Register.hoursErrorRequired")
                                                }} placeholder={t("Register.hourPlaceholder")} />




                                            <FieldValidator
                                                errors={errors} labelText={t("Register.created")} name="created" register={register} type="date" validationSchema={{
                                                    required: t("Register.createdErrorRequired"),
                                                    max: { value: moment().format("YYYY-MM-DD"), message: t("Register.createdErrorMax") }
                                                }} placeholder={t("Register.createdPlaceholder")} />

                                            <div className="col-span-full">
                                                <TextAreaValidator
                                                    errors={errors}
                                                    labelText={t("Register.description")} name="description" register={register} rows={2} validationSchema={{
                                                        required: t("Register.descriptionErrorRequired")
                                                    }
                                                    } placeholder={t("Register.descriptionPlaceholder")}
                                                ></TextAreaValidator>
                                            </div>

                                        </div>)}

                                    <div className="mt-4 flex   items-center justify-end gap-x-3">

                                        {(!loading && (<Button type="submit" isLoading={updating} disabled={updating} className="   px-3 py-2  bg-sixma-2 disabled:bg-sixma-2/60 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none text-white disabled:text-white text-sm">{t("EditHour.saveButton")}</Button>))}
                                        <Button type="button" onClick={closeModal} className="   px-3 py-2  bg-gray-100  disabled:text-white text-sm">{t("EditHour.cancelButton")}</Button>


                                    </div>
                                </form>
                            </Dialog.Panel>
                        </Transition.Child>
                    </div>
                </div>
            </Dialog>
        </Transition>
    </>)
});