
import { Outlet } from 'react-router-dom';
import SideBar from '../UI/organisms/SideBar'
import TopBar from '../UI/organisms/TopBar' 



function Main() {

 


    return (
        <>
        

            <div className='min-h-screen'>

                <SideBar></SideBar>

                <div className="lg:pl-72 min-h-screen">

                    <TopBar></TopBar>

                    <main className="py-4  bg-gray-100 min-h-screen">
                        <div className="px-4 sm:px-6 lg:px-8 h-full  ">
                            <Outlet />
                        </div>
                    </main>
                </div>
            </div>
        </>
    )
}

export default Main;