/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedQuery<InputType, OutputType> = string & {
  __generatedQueryInput: InputType;
  __generatedQueryOutput: OutputType;
};

export const getHours = /* GraphQL */ `query GetHours($id: ID!) {
  getHours(id: $id) {
    id
    description
    created 
    username
    hours
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedQuery<APITypes.GetHoursQueryVariables, APITypes.GetHoursQuery>;
export const listHours = /* GraphQL */ `query ListHours(
  $filter: ModelHoursFilterInput
  $limit: Int
  $nextToken: String
) {
  listHours(filter: $filter, limit: $limit, nextToken: $nextToken) {
    items {
      id
      description
      descriptionSearch
      created
      username
      hours
      createdAt
      updatedAt
      __typename
    }
    nextToken
    __typename
  }
}
` as GeneratedQuery<APITypes.ListHoursQueryVariables, APITypes.ListHoursQuery>;
