/* tslint:disable */
/* eslint-disable */
// this is an auto generated file. This will be overwritten

import * as APITypes from "../API";
type GeneratedMutation<InputType, OutputType> = string & {
  __generatedMutationInput: InputType;
  __generatedMutationOutput: OutputType;
};

export const createHours = /* GraphQL */ `mutation CreateHours(
  $input: CreateHoursInput!
  $condition: ModelHoursConditionInput
) {
  createHours(input: $input, condition: $condition) {
    id
    description
    descriptionSearch
    created
    username
    hours
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.CreateHoursMutationVariables,
  APITypes.CreateHoursMutation
>;
export const updateHours = /* GraphQL */ `mutation UpdateHours(
  $input: UpdateHoursInput!
  $condition: ModelHoursConditionInput
) {
  updateHours(input: $input, condition: $condition) {
    id
    description
    descriptionSearch
    created    
    username
    hours
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.UpdateHoursMutationVariables,
  APITypes.UpdateHoursMutation
>;
export const deleteHours = /* GraphQL */ `mutation DeleteHours(
  $input: DeleteHoursInput!
  $condition: ModelHoursConditionInput
) {
  deleteHours(input: $input, condition: $condition) {
    id
    description
    created    
    descriptionSearch
    username
    hours
    createdAt
    updatedAt
    __typename
  }
}
` as GeneratedMutation<
  APITypes.DeleteHoursMutationVariables,
  APITypes.DeleteHoursMutation
>;
