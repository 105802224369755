import { Label, TextArea } from "@sixma/web-ui";


function TextAreaValidator({ labelText, name, register, validationSchema, errors, placeholder, rows }:
    { labelText: string, name: string, register?: any, validationSchema?: any, errors?: any, rows: number, placeholder?: string }) {
 
    return (
        <div>
            <Label htmlFor={name}>{labelText}<span className='text-red-700'>{(validationSchema && validationSchema.required ? "*" : "")}</span></Label>
            <div>

                <TextArea
                    id={name}
                    name={name}
                    className="py-1.5 sm:text-sm sm:leading-6 w-full"
                    rows={rows}
                    errors={errors}
                    register={register}                
                    maxLength={100}
                    errorClass="ring-1 ring-inset focus:ring-1 focus:ring-inset ring-red-700 focus:ring-red-700"
                    nonErrorClass="focus:ring-1 focus:ring-inset focus:ring-sixma-2"
                    placeholder={placeholder}
                    validationSchema={validationSchema}
                ></TextArea>

                {errors && errors[name]?.type === "required" && (
                    <span className="mt-2 text-sm text-red-600">{errors[name]?.message}</span>
                )}
            </div>
        </div>

    );
}

export default TextAreaValidator;