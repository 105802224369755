import * as React from 'react';
import { ProtectedRoute } from './ProtectedRoute';
import { useAuth } from '../../domains/auth/providers/Auth';
import { Navigate, useLocation } from 'react-router-dom';

export type AuthenticationGuardProps = {
  children?: React.ReactElement;
  redirectPath?: string;
  guardType?: 'authenticated' | 'unauthenticated';
};

export const AuthenticationGuard: React.FC<AuthenticationGuardProps> = ({
  redirectPath = '/',
  guardType = 'authenticated',
  ...props
}) => {
  const { user } = useAuth();

  const location = useLocation();

  let isAllowed = guardType === 'authenticated' ? !!user : !user;

   if (user && location.pathname === "/") {
    
    return <Navigate to="/main" replace />;
  }


  return (
    <ProtectedRoute
      redirectPath={redirectPath}
      isAllowed={isAllowed}
      {...props}
    />
  );
};