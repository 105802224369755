
import { Fragment, useEffect, useState } from 'react'
import { Dialog, Menu, Transition } from '@headlessui/react'
import {
    Bars3Icon,
    BellIcon,
    ChartPieIcon,
    Cog6ToothIcon,
    FolderIcon,
    HomeIcon,
    UsersIcon,
    XMarkIcon,
    ClockIcon,
    FolderArrowDownIcon
} from '@heroicons/react/24/outline'
import { ChevronDownIcon, MagnifyingGlassIcon } from '@heroicons/react/20/solid'
import { useSidebarStore } from '../../../store/SidebarStore'
import { useTranslation } from 'react-i18next'
import Auth from '../../../api/Auth'
import { Link } from 'react-router-dom'
import { useAuth } from '../../../domains/auth/providers/Auth'
import { PageLoader } from '@sixma/web-ui'





function classNames(...classes: any[]) {
    return classes.filter(Boolean).join(' ')
}


function TopBar() {

    const { logout, getAttribute, containGroup } = useAuth();
    const [ isLoading, setIsLoading ] = useState(false);

    const userNavigation = [
        //   { name: 'Mi perfil', href: '#' },
        //{ name: 'TopBar.changePassword', href: '/account/change-password' },
        { name: 'TopBar.logout', href: '#', click: () => { setIsLoading(true); logout(); } },
    ]


    const { t } = useTranslation();
    const setOpen = useSidebarStore(state => state.setOpen);

    useEffect(() => {

    }, []);

    return (
        <>
        
        {((isLoading) && <PageLoader classNameSpin="text-sixma-2"> {t("General.loadingText")}</PageLoader>)}

        
            <div className="sticky top-0 z-40 flex h-16 shrink-0 items-center gap-x-4 border-b border-gray-200 bg-white px-4 shadow-sm sm:gap-x-6 sm:px-6 lg:px-8">
                <button type="button" className="-m-2.5 p-2.5 text-gray-700 lg:hidden" onClick={() => setOpen(true)}>
                    <span className="sr-only">Open sidebar</span>
                    <Bars3Icon className="h-6 w-6" aria-hidden="true" />
                </button>

                {/* Separator */}
                <div className="h-6 w-px bg-gray-900/10 lg:hidden" aria-hidden="true" />

                <div className="flex flex-1 gap-x-4 self-stretch lg:gap-x-6">
                    <div className="relative flex flex-1"></div>
                    <div className="flex items-center gap-x-4 lg:gap-x-6">
                        {/*       <button type="button" className="-m-2.5 p-2.5 text-gray-400 hover:text-gray-500">
                        <span className="sr-only">View notifications</span>
                        <BellIcon className="h-6 w-6" aria-hidden="true" />
                    </button> */}

                        {/* Separator */}
                        <div className="hidden lg:block lg:h-6 lg:w-px lg:bg-gray-900/10" aria-hidden="true" />

                        {/* Profile dropdown */}
                        <Menu as="div" className="relative">
                            <Menu.Button className="-m-1.5 flex items-center p-1.5">
                                <span className="sr-only">Open user menu</span>
                                <img
                                    className="h-8 w-8 rounded-full bg-gray-50"
                                    src={getAttribute("picture") ? getAttribute("picture") : "/images/user-empty.png"}
                                    alt=""
                                />
                                <span className="hidden lg:flex lg:items-center">
                                    <span className="ml-4 text-sm font-semibold leading-6 text-gray-900" aria-hidden="true">
                                        {getAttribute("given_name") + " " + getAttribute("family_name")}
                                    </span>
                                    <ChevronDownIcon className="ml-2 h-5 w-5 text-gray-400" aria-hidden="true" />
                                </span>
                            </Menu.Button>
                            <Transition
                                as={Fragment}
                                enter="transition ease-out duration-100"
                                enterFrom="transform opacity-0 scale-95"
                                enterTo="transform opacity-100 scale-100"
                                leave="transition ease-in duration-75"
                                leaveFrom="transform opacity-100 scale-100"
                                leaveTo="transform opacity-0 scale-95"
                            >
                                <Menu.Items className="absolute right-0 z-10 mt-2.5 w-32 origin-top-right rounded-md bg-white py-2 shadow-lg ring-1 ring-gray-900/5 focus:outline-none">
                                    {userNavigation.map((item) => (
                                        <Menu.Item key={item.name}>
                                            {({ active }) => (
                                                <Link
                                                    to={item.href}
                                                    onClick={item.click}
                                                    className={classNames(
                                                        active ? 'bg-gray-50' : '',
                                                        'block px-3 py-1 text-sm leading-6 text-gray-900'
                                                    )}
                                                >
                                                    {t(item.name)}
                                                </Link>
                                            )}
                                        </Menu.Item>
                                    ))}
                                </Menu.Items>
                            </Transition>
                        </Menu>
                    </div>
                </div>
            </div>
        </>
    );
}
export default TopBar;