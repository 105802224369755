import create from 'zustand';
import { TypeAlertMessage } from '@sixma/web-ui';

interface IMessage {
    messages: any[],
    currentMessage: any,
    enqueue: (message: string, typeMessage: TypeAlertMessage) => void,
    dequeue: () => any,
}

export const useMessageStore = create<IMessage>((set, get) => ({
    messages: [],
    currentMessage: null,
    enqueue: (message: string, typeMessage: TypeAlertMessage) => {
        set((state) => ({ messages: [...state.messages, { message: message, typeMessage: typeMessage }] }));
    },
    dequeue: () => {
        set((state) => ({ currentMessage: state.messages.shift() }));
    }

}));