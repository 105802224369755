
import { RouterProvider } from "react-router-dom";
import { router } from "./router/Router";
import { AuthProvider } from "./domains/auth/providers/Auth"; 
import AlertMessageGlobal from "./components/UI/atoms/AlertMessageGlobal/AlertMessageGlobal";
function App() {
  return (
    <>
  
      <AuthProvider>
        <AlertMessageGlobal>
          <RouterProvider router={router} />
        </AlertMessageGlobal>
      </AuthProvider>
    </>
  );
}

export default App;
