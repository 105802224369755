import { Button,  LinkAction } from "@sixma/web-ui";
import { useTranslation } from "react-i18next";
import Logo from "../../UI/atoms/Logo";
import FieldValidator from "../../UI/molecules/FieldValidator";
import { useForm } from "react-hook-form";

export default function AccountRecoverPassword() {

    const { t } = useTranslation();

    const { register, handleSubmit, formState: { errors }, } = useForm();


    const onSubmit = async (data: any) => {
        try {

            //await login(data.email, data.password, false)
        } catch (error: any) {
            /*if (error.toString().indexOf("UserNotFoundException") !== -1)
                alertRef.current?.show(t("Login.userNotFoundError"), TypeAlertMessage.error);*/

        } finally {
            //setValidating(false);
        }
    }

    return (
        <div className="flex min-h-full flex-1 flex-col justify-center py-12 sm:px-6 lg:px-8 px-4 bg-gray-100 h-screen">
            <div className="sm:mx-auto sm:w-full sm:max-w-md text-center">

            <Logo className="m-auto h-28" isVertical={true} />
                <h2 className="mt-3 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                    {t("AccountRecoverPassword.title")}
                </h2>
            </div>

            <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-[400px]">
                <div className="bg-white   py-6     px-8  shadow rounded-md">
                    <form onSubmit={handleSubmit(onSubmit)} className="space-y-6" action="#" method="POST">
                        <FieldValidator
                            errors={errors} labelText={t("AccountRecoverPassword.emailNameField")} name="email" register={register} type="email" validationSchema={{
                                required: t("AccountRecoverPassword.emailErrorRequired")
                            }} placeholder={t("AccountRecoverPassword.emailPlaceholder")} />

 

                        <div>
                            <Button type="submit"
                                className="w-full px-3 py-2.5 justify-center items-center bg-sixma-2 disabled:bg-sixma-2/60 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none text-white disabled:text-white text-sm"
                            >{t("AccountRecoverPassword.recoverPasswordButton")}</Button>
                        </div>
                    </form>

       
                </div>

                <p className="mt-10 text-center text-sm text-gray-500">
                    <LinkAction className="text-sixma-1 hover:text-sixma-1-500" to="/">{t("AccountRecoverPassword.backButton")}</LinkAction>
                </p>
            </div>
        </div>

    );
}