import { Label, TextField } from "@sixma/web-ui"; 







function FieldValidator({ labelText, type, name, register, validationSchema, errors, placeholder }:
    { labelText: string, type: string, name: string, register?: any, validationSchema?: any, errors?: any, placeholder?: string  }) {

    return (
        <div>
            <Label htmlFor={name}>{labelText}<span className='text-red-700'>{(validationSchema && validationSchema.required ? "*" : "")}</span></Label>
            <div>
                <TextField
                    className="w-full py-1.5 sm:text-sm sm:leading-6 "
                    id={name} 
                    name={name}
                    type={type}
                    register={register}
                    placeholder={placeholder}
                    errors={errors}
                    errorClass="ring-1 ring-inset focus:ring-1 focus:ring-inset ring-red-700 focus:ring-red-700" 
                    nonErrorClass="focus:ring-1 focus:ring-inset focus:ring-sixma-2" 
                    validationSchema={validationSchema}></TextField>
                {(errors && errors[name]?.type === "required" || errors && errors[name]?.type === "max") && (
                    <span className="mt-2 text-sm text-red-600">{errors[name]?.message}</span>
                )}
            </div>
        </div>

    );
}

export default FieldValidator;