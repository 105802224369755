function Logo({ isWhite = false, h = "h-10", className = "", isVertical = false }: { isWhite?: boolean, h?: string, className?: string, isVertical?: boolean }) {
    return (
        <>
            {(!isVertical && <img
                className={h + " w-auto " + className}
                src={(isWhite ? "/images/logo-white.svg" : "/images/logo.svg")}
                alt="Sixma It Solutions"
            />)}

            {(isVertical && <img
                className={h + " w-auto " + className}
                src={ "/images/logo-vertical.svg" }
                alt="Sixma It Solutions"
            />)}
        </>
    );
}

export default Logo;