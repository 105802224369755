import { Button, GoogleButton, LinkAction, PageLoader } from "@sixma/web-ui";
import { useTranslation } from "react-i18next";
import Logo from "../../UI/atoms/Logo";
import FieldValidator from "../../UI/molecules/FieldValidator";
import { useForm } from "react-hook-form";
import Auth from "../../../api/Auth";
import { useState } from "react";

export default function AccountRegister() {

    const { t } = useTranslation();
    const [providerValidating, setProviderValidating] = useState(false);

    const { register, handleSubmit, formState: { errors }, } = useForm();


    const onSubmit = async (data: any) => {
        try {

            //await login(data.email, data.password, false)
        } catch (error: any) {
            /*if (error.toString().indexOf("UserNotFoundException") !== -1)
                alertRef.current?.show(t("Login.userNotFoundError"), TypeAlertMessage.error);*/

        } finally {
            //setValidating(false);
        }
    }

    const goToGoogle = () => {
        setProviderValidating(true);
        Auth.SignWithRedirect();
        setTimeout(() => setProviderValidating(false), 3000);
    };

    return (
        <>
            {((providerValidating) && <PageLoader classNameSpin="text-sixma-2"> {t("General.loadingText")}</PageLoader>)}

            <div className="flex min-h-screen flex-1 flex-col justify-center  py-12 px-4  lg:px-8 bg-gray-100">
                <div className="sm:mx-auto w-full sm:max-w-md text-center">

                <Logo className="m-auto h-28" isVertical={true} />
                    <h2 className="mt-6 text-center text-2xl font-bold leading-9 tracking-tight text-gray-900">
                        {t("AccountRegister.title")}
                    </h2>
                </div>

                <div className="mt-3 sm:mx-auto sm:w-full sm:max-w-[400px]">
                    <div className="bg-white   py-6     px-8  shadow rounded-md">
                        <form onSubmit={handleSubmit(onSubmit)} className="space-y-3" action="#" method="POST">
                            <FieldValidator
                                errors={errors} labelText={t("AccountRegister.emailNameField")} name="email" register={register} type="email" validationSchema={{
                                    required: t("AccountRegister.emailErrorRequired")
                                }} placeholder={t("AccountRegister.emailPlaceholder")} />


                            <FieldValidator
                                errors={errors} labelText={t("AccountRegister.givenNameField")} name="givenName" register={register} type="text" validationSchema={{
                                    required: t("AccountRegister.givenNameErrorRequired")
                                }} placeholder={t("AccountRegister.givenNamePlaceholder")} />


                            <FieldValidator
                                errors={errors} labelText={t("AccountRegister.familyNameField")} name="familyName" register={register} type="text" validationSchema={{
                                    required: t("AccountRegister.familyNameErrorRequired")
                                }} placeholder={t("AccountRegister.familyNamePlaceholder")} />





                            <FieldValidator
                                errors={errors} labelText={t("AccountRegister.passwordNameField")} name="password" register={register} type="password" validationSchema={{
                                    required: t("AccountRegister.passwordErrorRequired")
                                }} />


                            <FieldValidator
                                errors={errors} labelText={t("AccountRegister.repeatPasswordNameField")} name="repeatPassword" register={register} type="password" validationSchema={{
                                    required: t("AccountRegister.repeatPasswordErrorRequired")
                                }} />


                            <div>
                                <Button type="submit"
                                    className="w-full px-3 py-2 justify-center items-center bg-sixma-2 disabled:bg-sixma-2/60 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none text-white disabled:text-white text-sm"
                                >{t("AccountRegister.registerButton")}</Button>
                            </div>
                        </form>

                        <div>
                            <div className="relative mt-6">
                                <div className="absolute inset-0 flex items-center" aria-hidden="true">
                                    <div className="w-full border-t border-gray-200" />
                                </div>
                                <div className="relative flex justify-center text-sm font-medium leading-6">
                                    <span className="bg-white px-6 text-gray-900">{t("AccountRegister.orRegisterWith")}</span>
                                </div>
                            </div>

                            <div className="mt-6">
                                <GoogleButton click={() => goToGoogle()} className="w-full" />
                            </div>
                        </div>
                    </div>

                    <p className="mt-10 text-center text-sm text-gray-500">
                        <LinkAction className="text-sixma-1 hover:text-sixma-1-500" to="/">{t("AccountRegister.backButton")}</LinkAction>
                    </p>
                </div>
            </div>
        </>
    );
}