


function Card({ title, text, icon, colorIcon, subText, loading }: { title: string, text: JSX.Element, icon: JSX.Element, colorIcon: string, subText: JSX.Element, loading?: boolean }) {
    return (
        <div className="overflow-hidden relative px-5 py-6   bg-white   rounded-lg   dark:border-gray-700 sm:p-6 dark:bg-gray-800">
            <div className=" text-md mb-2 font-bold ">{title}</div>


            <div className="h-14">
                {!loading && <div className="flex items-center ">

                    <div className="mx-0">

                        <h4 className="text-gray-700">{text}</h4>
                        <span className="text-gray-500 text-xs">{subText}</span>
                    </div>
                </div>}

                {loading && <div className="  w-full h-full  animate-pulse  ">
                    <div className="absolute ">
                        <div className="h-5 bg-gray-200 rounded-md dark:bg-gray-700 w-10 mb-3"></div>

                        <div className="h-5 bg-gray-200 rounded-md dark:bg-gray-700 w-32"></div>
                    </div>
                </div>}

                <div className="overflow-hidden  ">
                    <div className={"  absolute top-0  -right-32 bg-opacity-60  w-[210px]   px-[22px] rounded-full h-full flex items-center  overflow-hidden    " + colorIcon}>
                        {icon}
                    </div>
                </div>
            </div>

        </div>
    );

}

export default Card;