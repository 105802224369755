import {
    signUp, resendSignUpCode, confirmSignUp, signIn, getCurrentUser, signOut,
    fetchUserAttributes,

    confirmResetPassword,
    signInWithRedirect,
    fetchAuthSession,
    updatePassword,
    autoSignIn,
    confirmSignIn,
    JWT,
    decodeJWT,
    TokenProvider

} from '@aws-amplify/auth';
import { get } from 'aws-amplify/api'
import { User } from '../models/User';



const SignUp = async (email: any, password: any, locale: any) => {
    await signUp({
        username: email,
        password
    });
};

const ResendConfirmationCode = async (email: any) => {
    await resendSignUpCode(email);
};

const SignWithRedirect = async () => {



    await signInWithRedirect({ provider: "Google" });
}

const FetchUserAttributes = async () => {
    return await fetchUserAttributes();
}

const ConfirmSignUp = async (email: any, code: any) => {
    await confirmSignUp({ username: email, confirmationCode: code });
};

const SignIn = async (email: any, pwd: any, remember: boolean) => {
    const auth = await signIn({ username: email, password: pwd });

    console.log(auth)
    if (auth.nextStep.signInStep == "CONFIRM_SIGN_IN_WITH_NEW_PASSWORD_REQUIRED") {
        await confirmSignIn({
            challengeResponse: pwd
        });
    }



    /* if (remember) await AmplifyAuth.rememberDevice();
            else await AmplifyAuth.forgetDevice();  */
};

const GetUser = async () => {
    return await getCurrentUser();
};


const GetSession = async () => {
    return await fetchAuthSession();
};

const SignOut = async () => {
    await signOut({ global: true });
};

const ListUsers = async () => {

    const session = (await fetchAuthSession())

    let apiName = 'AdminQueries';
    let path = '/listUsers';

    let options = {
        headers: {
            'Content-Type': 'application/json',
            Authorization: session.tokens?.accessToken.toString() as string
        }
    }
    let response = await get({ apiName, path, options }).response;
    let result = (await response.body.json()) as any;

    if (result.Users) {
        let users: Array<User> = [];
        result.Users.forEach((element: any) => {
            let givenName = "", familyName = "", email = "";
            if (element.Attributes && element.Attributes.length > 0) {
                email = element.Attributes.find((field: any) => field.Name == "email").Value;
                familyName = element.Attributes.find((field: any) => field.Name == "family_name").Value;
                givenName = element.Attributes.find((field: any) => field.Name == "given_name").Value;
            }

            let user: User = {
                username: element.Username,
                email: email,
                familyName: familyName,
                givenName: givenName
            };
            users.push(user);

        });
        return users;
    }
    throw "ErrorListUsers: Error list users";
}

const Auth = {
    SignUp,
    ResendConfirmationCode,
    ConfirmSignUp,
    SignIn,
    SignWithRedirect,
    GetUser,
    SignOut,
    FetchUserAttributes,
    GetSession,
    ListUsers
};

export default Auth; 