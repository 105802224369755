import { AlertMessage, IAlertMessage, TypeAlertMessage } from "@sixma/web-ui";
import { useMessageStore } from "../../../../store/MessageStore";
import { useEffect, useRef } from "react";
import React from "react";


const AlertMessageContext = React.createContext({
    show: (message: string, type: TypeAlertMessage) => { }
})

export default function AlertMessageGlobal({ children }: { children: any }) {    
    const sRef = useRef<IAlertMessage>();
    const show = (message: string, type: TypeAlertMessage) => {
        sRef.current?.show(message, type);
    }

    return (<>
        <AlertMessage ref={sRef}></AlertMessage>
        <AlertMessageContext.Provider value={{ show }}>
            {children}
        </AlertMessageContext.Provider>
    </>)
}

export const useAlertMessage = () => React.useContext(AlertMessageContext);