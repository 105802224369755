import { ArrowLongLeftIcon, ArrowLongRightIcon, ChevronDownIcon, ClockIcon, PencilSquareIcon, XCircleIcon, XMarkIcon } from "@heroicons/react/24/outline";
import { useTranslation } from "react-i18next";
import { Button, Label, LinkIcon, TextField } from "@sixma/web-ui";



function MyRegister() {

    const { t } = useTranslation();
    const tasks = [
        { id: 1, date: '12-01-2024 10:30', time: '8', task: 'Tarea agregada de lo que estaba pendiente' },
        { id: 2, date: '12-01-2024 10:30', time: '8', task: 'Tarea agregada de lo que estaba pendiente' },
        { id: 3, date: '12-01-2024 10:30', time: '8', task: 'Tarea agregada de lo que estaba pendiente' },
        { id: 4, date: '12-01-2024 10:30', time: '8', task: 'Tarea agregada de lo que estaba pendiente' },
        { id: 10, date: '12-01-2024 10:30', time: '8', task: 'Tarea agregada de lo que estaba pendiente Tarea agregada de lo que estaba pendiente' },
        { id: 100, date: '12-01-2024 10:30', time: '8', task: 'Tarea agregada de lo que estaba pendiente' },
    ]
    return (

        <div className="mt-2  h-full">
            <div className="mx-auto   px-0 sm:px-2 h-full ">

                <h1 className="text-2xl font-bold">{t("MyRegister.title")}</h1>

                <div className=" bg-white  mt-6     dark:border-gray-700   dark:bg-gray-800  min-w-full  align-middle   rounded-lg   overflow-hidden  px-5 py-6">

                    <form className="grid grid-cols-1 sm:grid-cols-2 md:grid-cols-4 xl:grid-cols-5 gap-x-6 items-end">


                        <div className="mt-1">
                            <Label htmlFor="id">{t("MyRegister.id")}</Label>
                            <div className="mt-1">
                                <TextField id="id" name="id" type="number" placeholder={t("MyRegister.idPlaceholder")}></TextField>
                            </div>
                        </div>

                        <div className="mt-1">
                            <Label htmlFor="from">{t("MyRegister.from")}</Label>
                            <div className="mt-1">
                                <TextField id="from" name="from" type="date" placeholder={t("MyRegister.fromPlaceholder")}></TextField>
                            </div>
                        </div>

                        <div className="mt-1">
                            <Label htmlFor="to">{t("MyRegister.to")}</Label>
                            <div className="mt-1">
                                <TextField id="to" name="to" type="date" placeholder={t("MyRegister.toPlaceholder")}></TextField>
                            </div>
                        </div>

                        <div className="mt-1">

                            <Label htmlFor="description">{t("MyRegister.description")}</Label>
                            <div className="mt-1">
                                <TextField
                                    id="description"
                                    name="description"
                                    type="text"
                                    placeholder={t("MyRegister.descriptionPlaceholder")}
                                ></TextField>
                            </div>

                        </div>
                        <div className="mt-2 sm:mt-2 ">


                            <Button type="button">{t("MyRegister.findButton")}</Button>
                        </div>
                    </form>


                    <table className="min-w-full mt-6  h-36">
                        <thead className="bg-gray-50  sticky top-0">
                            <tr>
                                <th scope="col" className=" w-20  py-2  px-2   hidden sm:table-cell text-right text-[11px] font-semibold text-gray-600  uppercase">
                                    # <span className=" inline-block align-middle ml-2 flex-none rounded text-gray-800   px-1 py-1   group-hover:visible group-focus:visible">
                                        <ChevronDownIcon className="h-3 w-3" aria-hidden="true" /></span>
                                </th>
                                <th scope="col" className=" w-36  py-2  px-2  text-left  sm:text-center text-[11px] font-semibold text-gray-600  uppercase">
                                    {t("MyRegister.tableTaskRegisterdDate")}  <span className=" inline-block align-middle ml-2 flex-none rounded text-gray-800   px-1 py-1   group-hover:visible group-focus:visible">
                                        <ChevronDownIcon className="h-3 w-3" aria-hidden="true" /></span>
                                </th>
                                <th scope="col" className="   px-2 py-2   text-[11px] text-center font-semibold text-gray-600 uppercase">
                                    {t("MyRegister.tableTaskRegisterdHours")}
                                </th>
                                <th scope="col" className="  px-2 py-2 text-center hidden sm:table-cell text-[11px] font-semibold text-gray-600 uppercase">
                                    {t("MyRegister.tableTaskRegisterdTask")}
                                </th>
                                <th scope="col" className="  px-2 py-2 text-center text-[11px] font-semibold text-gray-600 uppercase">
                                    {t("MyRegister.tableTaskRegisterdActions")}
                                </th>
                            </tr>
                        </thead>
                        <tbody>
                            {tasks.map((task) => (
                                <tr key={task.id} className="even:bg-gray-50">

                                    <td className="   px-3 py-3 text-gray-900 text-xs hidden sm:table-cell">
                                        <div className="text-right font-bold">
                                            {task.id}
                                        </div>
                                    </td>

                                    <td className="whitespace-nowrap px-3 py-3 text-xs  text-gray-900 ">
                                        <div className="sm:hidden text-wrap text-[11px] font-bold">
                                            # {task.id}
                                        </div>
                                        <div className="sm:hidden text-wrap text-[11px] mt-1">
                                            {task.task}
                                        </div>
                                        <div className="text-[10px] sm:text-xs mt-2 sm:mt-0"> <ClockIcon className="h-4 sm:h-5 inline-block align-middle text-gray-500" />  <span className="align-middle"> {task.date}</span></div>

                                    </td>
                                    <td className="whitespace-nowrap px-3 py-3 text-xs   text-center text-gray-900">
                                        {task.time} <span className="text-[10px]">{t("MyRegister.hh")}</span>
                                    </td>
                                    <td className="   px-3 py-3 text-gray-900 text-xs hidden sm:table-cell">
                                        <div className="text-center ">
                                            {task.task}
                                        </div>
                                    </td>
                                    <td className=" text-center w-56  px-3 py-3 text-gray-900 text-xs">
                                        <div className="inline-block px-3">
                                            <LinkIcon to="" icon={<PencilSquareIcon className="h-5 inline-block" />} className="text-sixma-1 hover:text-sixma-1-500">{t("MyRegister.editLink")}</LinkIcon>
                                        </div>
                                        <div className="inline-block px-3">
                                            <LinkIcon to="" icon={<XMarkIcon className="h-5 inline-block" />} className="text-sixma-1 hover:text-sixma-1-500">{t("MyRegister.deleteLink")}</LinkIcon>
                                        </div>
                                    </td>
                                </tr>
                            ))}
                        </tbody>
                    </table>


                    <nav className="flex items-center justify-between border-t border-gray-200 px-4 sm:px-0 mt-4">
                        <div className="-mt-px flex w-0 flex-1">
                            <a
                                href="#"
                                className="inline-flex items-center border-t-2 border-transparent pr-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            >
                                <ArrowLongLeftIcon className="mr-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                                {t("MyRegister.beforeLink")}
                            </a>
                        </div>
                        <div className="hidden md:-mt-px md:flex">
                            <a
                                href="#"
                                className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            >
                                1
                            </a>
                            {/* Current: "border-indigo-500 text-indigo-600", Default: "border-transparent text-gray-500 hover:text-gray-700 hover:border-gray-300" */}
                            <a
                                href="#"
                                className="inline-flex items-center border-t-2 border-sixma-2 px-4 pt-4 text-sm font-medium text-sixma-2"
                                aria-current="page"
                            >
                                2
                            </a>
                            <a
                                href="#"
                                className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            >
                                3
                            </a>
                            <span className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500">
                                ...
                            </span>
                            <a
                                href="#"
                                className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            >
                                8
                            </a>
                            <a
                                href="#"
                                className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            >
                                9
                            </a>
                            <a
                                href="#"
                                className="inline-flex items-center border-t-2 border-transparent px-4 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            >
                                10
                            </a>
                        </div>
                        <div className="-mt-px flex w-0 flex-1 justify-end">
                            <a
                                href="#"
                                className="inline-flex items-center border-t-2 border-transparent pl-1 pt-4 text-sm font-medium text-gray-500 hover:border-gray-300 hover:text-gray-700"
                            >
                                {t("MyRegister.afterLink")}
                                <ArrowLongRightIcon className="ml-3 h-5 w-5 text-gray-400" aria-hidden="true" />
                            </a>
                        </div>
                    </nav>

                </div>
            </div>
        </div>

    );
}

export default MyRegister;